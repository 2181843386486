// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as CityStateCountry from "../../models/CityStateCountry.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.array(CityStateCountry.decoder);

var Res = {
  decoder: decoder
};

function exec(term, onlySearchCities, withProducts, showHidden) {
  return Rest.$$fetch("/locations/search_cities_states_countries?term=" + (term + ((
                  onlySearchCities ? "&onlyCities=true" : ""
                ) + ((
                    withProducts ? "&withProducts=true" : ""
                  ) + (
                    showHidden ? "&showHidden=true" : ""
                  )))), "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Res ,
  exec ,
}
/* decoder Not a pure module */
