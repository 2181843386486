// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Validator from "Utils/validator";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function empty(leadType, providerName, locationName) {
  return {
          firstName: "",
          lastName: "",
          companyName: "",
          email: "",
          phone: "",
          dialCountryCode: "",
          country: "",
          message: "",
          isAgree: false,
          newsletterSubscription: false,
          providerName: providerName,
          locationName: locationName,
          leadType: leadType
        };
}

function toJson(input) {
  var match = input.leadType;
  var tmp;
  tmp = match === "Provider" ? "Provider" : "Location";
  return Json_Encode$JsonCombinators.object([
              [
                "name",
                input.firstName + (" " + input.lastName)
              ],
              [
                "dialCode",
                input.dialCountryCode
              ],
              [
                "phone",
                Js_string.replace(input.dialCountryCode, "", input.phone).trim()
              ],
              [
                "dialCodeCountry",
                input.country
              ],
              [
                "email",
                input.email
              ],
              [
                "company",
                input.companyName
              ],
              [
                "message",
                input.message
              ],
              [
                "newsletterSubscription",
                input.newsletterSubscription
              ],
              [
                "providerName",
                input.providerName
              ],
              [
                "locationName",
                input.locationName
              ],
              [
                "leadType",
                tmp
              ]
            ]);
}

var Input = {
  empty: empty,
  toJson: toJson
};

function toJs(input) {
  return {
          firstName: input.firstName,
          lastName: input.lastName,
          companyName: input.companyName,
          email: input.email,
          phone: input.phone,
          isAgree: input.isAgree
        };
}

var ValidationInput = {
  toJs: toJs
};

function fromJs(js) {
  return {
          firstName: Caml_option.nullable_to_opt(js.firstName),
          lastName: Caml_option.nullable_to_opt(js.lastName),
          companyName: Caml_option.nullable_to_opt(js.companyName),
          email: Caml_option.nullable_to_opt(js.email),
          phone: Caml_option.nullable_to_opt(js.phone),
          isAgree: Caml_option.nullable_to_opt(js.isAgree)
        };
}

var ExternalValidationResult = {
  fromJs: fromJs
};

function convertResult(field) {
  if (field !== undefined) {
    return {
            TAG: "Error",
            _0: Caml_option.valFromOption(field)
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function fromExternalResult(results) {
  return {
          firstName: convertResult(results.firstName),
          lastName: convertResult(results.lastName),
          companyName: convertResult(results.companyName),
          email: convertResult(results.email),
          phone: convertResult(results.phone),
          isAgree: convertResult(results.isAgree)
        };
}

var ValidationResult = {
  convertResult: convertResult,
  fromExternalResult: fromExternalResult
};

var validationFields = [
  {
    id: "firstName",
    validation: [
      {
        rule: "required",
        message: "First name is required."
      },
      {
        rule: "empty",
        message: "Enter valid first name."
      }
    ]
  },
  {
    id: "lastName",
    validation: [
      {
        rule: "required",
        message: "Last name is required."
      },
      {
        rule: "empty",
        message: "Enter valid last name."
      }
    ]
  },
  {
    id: "companyName",
    validation: [
      {
        rule: "required",
        message: "Company is required."
      },
      {
        rule: "empty",
        message: "Enter valid company."
      }
    ]
  },
  {
    id: "email",
    validation: [
      {
        rule: "required",
        message: "Email is required."
      },
      {
        rule: "email",
        message: "Email is not valid."
      },
      {
        rule: "empty",
        message: "Enter valid email."
      }
    ]
  },
  {
    id: "phone",
    validation: [{
        rule: "required",
        message: "Phone is required."
      }]
  },
  {
    id: "isAgree",
    validation: [{
        rule: "required",
        message: "Agreement is required."
      }]
  }
];

function all(values) {
  return fromExternalResult(fromJs(Validator.formErrorsValidations(toJs(values), validationFields)));
}

function valid(result) {
  if (result.firstName.TAG === "Ok" && result.lastName.TAG === "Ok" && result.companyName.TAG === "Ok" && result.email.TAG === "Ok" && result.phone.TAG === "Ok" && result.isAgree.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

var Validate = {
  validationFields: validationFields,
  all: all,
  valid: valid
};

export {
  Input ,
  ValidationInput ,
  ExternalValidationResult ,
  ValidationResult ,
  Validate ,
}
/* Utils/validator Not a pure module */
