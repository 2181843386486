// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Csrf from "../Csrf.res.js";
import * as Http from "../Http.res.js";
import * as Project from "../../models/Project.res.js";
import * as $$Promise from "../../bindings/Promise.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../loggers/SentryLogger.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return field.required("projectId", Project.Id.decoder);
    });

var Res = {
  decoder: decoder
};

function exec(category, title, description, isAgent, agency, businessName, businessContactName, headquartersAddress, businessPhone, businessEmail, contacts, documents) {
  var formData = new FormData();
  formData.append("project[title]", title);
  formData.append("project[description]", description);
  formData.append("project[isAgent]", isAgent);
  formData.append("project[agency]", agency);
  formData.append("project[businessName]", businessName);
  formData.append("project[businessContactName]", businessContactName);
  formData.append("project[headquartersAddress]", headquartersAddress);
  formData.append("project[businessPhone]", businessPhone);
  formData.append("project[businessEmail]", businessEmail);
  Belt_Array.forEach(contacts, (function (contact) {
          formData.append("project[contacts][][fullName]", contact.fullName);
          formData.append("project[contacts][][jobTitle]", Belt_Option.getWithDefault(contact.jobTitle, ""));
          formData.append("project[contacts][][email]", contact.email);
          formData.append("project[contacts][][companyName]", contact.companyName);
          formData.append("project[contacts][][phone]", contact.phone);
          formData.append("project[contacts][][isPrimary]", contact.isPrimary);
          formData.append("project[contacts][][isManager]", contact.isManager);
        }));
  Belt_Array.forEach(documents, (function ($$document) {
          formData.append("project[documents][]", $$document);
        }));
  var ep;
  switch (category.TAG) {
    case "Colocation" :
        ep = "/projects/colocation/rfp";
        break;
    case "Cloud" :
        ep = category._0 === "IaasServer" ? "/projects/cloud/iaas-server" : "/projects/cloud/iaas-storage";
        break;
    case "Internet" :
        ep = "/projects/internet/rfp";
        break;
    case "Network" :
        switch (category._0) {
          case "SdWan" :
              ep = "/projects/network/sdwan";
              break;
          case "PrivateLine" :
              ep = "/projects/network/private-line";
              break;
          case "Mpls" :
              ep = "/projects/network/mpls";
              break;
          
        }
        break;
    case "BareMetal" :
        ep = "/projects/bare_metal/rfp";
        break;
    
  }
  var endpoint = "/api/v1" + ep;
  return $$Promise.andThen(fetch(endpoint, {
                  method: Http.Method.toFetch({
                        NAME: "Post",
                        VAL: undefined
                      }),
                  body: Caml_option.some(formData),
                  headers: Caml_option.some(new Headers([[
                              "X-CSRF-Token",
                              Belt_Option.getExn(Csrf.token())
                            ]])),
                  credentials: "same-origin"
                }), (function (response) {
                if (response.ok) {
                  return $$Promise.map(response.json(), (function (json) {
                                return {
                                        TAG: "Ok",
                                        _0: Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder))
                                      };
                              }));
                }
                var match = Http.ContentType.fromHeaders(response.headers);
                if (match !== undefined && typeof match !== "object") {
                  switch (match) {
                    case "Text" :
                        return $$Promise.map(response.text(), (function (text) {
                                      SentryLogger.error4({
                                            rootModule: "CreateNewProjectRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "CreateNewProjectRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "text"
                                          ], [
                                            "responseBody",
                                            text
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: "Opaque"
                                            };
                                    }));
                    case "Json" :
                        return $$Promise.map(response.json(), (function (json) {
                                      SentryLogger.error4({
                                            rootModule: "CreateNewProjectRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "CreateNewProjectRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "json"
                                          ], [
                                            "responseBody",
                                            json
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: "Opaque"
                                            };
                                    }));
                    case "Blob" :
                        return $$Promise.map(response.blob(), (function (blob) {
                                      SentryLogger.error4({
                                            rootModule: "CreateNewProjectRequest",
                                            subModulePath: /* [] */0,
                                            value: "exec",
                                            fullPath: "CreateNewProjectRequest.exec"
                                          }, "Fetch::RequestError", [
                                            "code",
                                            response.status
                                          ], [
                                            "status",
                                            response.statusText
                                          ], [
                                            "contentType",
                                            "blob"
                                          ], [
                                            "responseBody",
                                            blob
                                          ]);
                                      return {
                                              TAG: "Error",
                                              _0: "Opaque"
                                            };
                                    }));
                    
                  }
                }
                SentryLogger.error3({
                      rootModule: "CreateNewProjectRequest",
                      subModulePath: /* [] */0,
                      value: "exec",
                      fullPath: "CreateNewProjectRequest.exec"
                    }, "Fetch::RequestError", [
                      "code",
                      response.status
                    ], [
                      "status",
                      response.statusText
                    ], [
                      "contentType",
                      "N/A"
                    ]);
                return $$Promise.resolve({
                            TAG: "Error",
                            _0: "Opaque"
                          });
              }));
}

var $$FormData$1;

export {
  $$FormData$1 as $$FormData,
  Res ,
  exec ,
}
/* decoder Not a pure module */
