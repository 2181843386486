// extracted by mini-css-extract-plugin
export var column = "Textarea__column___xVUZ";
export var disabled = "Textarea__disabled__YpV2d";
export var error = "Textarea__error__IwJDx";
export var icon = "Textarea__icon__jczwi";
export var marginLeft = "Textarea__marginLeft__pCnwz";
export var marginRight = "Textarea__marginRight__JNABj";
export var row = "Textarea__row__Ls5o2";
export var sizeLg = "Textarea__sizeLg__Cvjix";
export var sizeMd = "Textarea__sizeMd__iBebh";
export var sizeSm = "Textarea__sizeSm__oIjyY";
export var sizeXl = "Textarea__sizeXl__XuAOD";
export var sizeXs = "Textarea__sizeXs__DbB8J";
export var sizeXxl = "Textarea__sizeXxl__NSzUh";
export var sizeXxs = "Textarea__sizeXxs__ZD0eK";
export var sizeXxxl = "Textarea__sizeXxxl__W0VuY";
export var sizeXxxs = "Textarea__sizeXxxs__XV4NB";
export var sizeXxxxl = "Textarea__sizeXxxxl__rHASO";
export var sizeXxxxxl = "Textarea__sizeXxxxxl__oUJCY";
export var spinner = "Textarea__spinner__Zqa99";
export var textarea = "Textarea__textarea__dzh0W";
export var withRightSideAddon = "Textarea__withRightSideAddon__gfy7p";
export var wrapper = "Textarea__wrapper__QBipc";