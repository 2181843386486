// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as ContactProviderData from "../../bundles/provider-detail/contact-provider-modal/ContactProviderData.res.js";

var toJson = ContactProviderData.Input.toJson;

var Req = {
  toJson: toJson
};

function exec(input) {
  return Rest.$$fetch("/leads/provider_location", {
              NAME: "Post",
              VAL: toJson(input)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* Rest Not a pure module */
